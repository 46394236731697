"use client";

import type { ReactNode } from "react";
import { createContext, useState } from "react";
import { Dialog } from "@/components/ui/dialog";

interface DialogState {
  open: (content: ReactNode) => void;
  close: () => void;
}

export const DialogContext = createContext<DialogState | undefined>(undefined);

export function DialogProvider({
  children,
}: Readonly<{
  children: ReactNode;
}>) {
  const [dialogContent, setDialogContent] = useState<ReactNode>(null);

  const open = (content: ReactNode) => {
    setDialogContent(content);
  };

  const close = () => {
    setDialogContent(null);
  };

  const onOpenChange = (open: boolean) => {
    if (open) return;
    close();
  };

  return (
    <DialogContext.Provider value={{ open, close }}>
      {children}
      <Dialog open={!!dialogContent} onOpenChange={onOpenChange} modal={false}>
        {!!dialogContent && <div className="fixed inset-0 z-50 bg-black/80 " />}
        {dialogContent}
      </Dialog>
    </DialogContext.Provider>
  );
}
